<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="childAgencyWrapper">
          <h1 class="text-center text-secondary">Agency Details</h1>
          <ErrorFormField :msgArray="errors.common" v-if="errors.common !== undefined" />
          <div class="row g-3">
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adAgencyName"
                placeholder="Agency Name"
                maxlength="20"
                :value="$data.agent.agency_name"
              />
              <label for="adFirstName" class="label-require">Agency Name</label>
              <ErrorFormField
                :msgArray="errors.adFirstName"
                v-if="errors.adFirstName !== undefined"
              />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adPAN"
                placeholder="PAN"
                maxlength="20"
                :value="$data.agent.pan"
              />
              <label for="adPAN" class="label-require">PAN</label>
              <ErrorFormField :msgArray="errors.adPAN" v-if="errors.adPAN !== undefined" />
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="adPANCardCopy" class="">PAN Card Copy</label>
              <img :src="$data.agent.pan_card_copy" class="img-thumbnail" />
              <input
                type="file"
                class="form-control"
                id="adPANCardCopy"
                accept=".png, .jpg, .jpeg, .gif, .webp"
              />
              <input type="hidden" class="form-control" id="adPANCardCopyHidden" />
              <ErrorFormField
                :msgArray="errors.adPANCardCopy"
                v-if="errors.adPANCardCopy !== undefined"
              />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="number"
                class="form-control"
                id="adMobileNumber"
                placeholder="Mobile Number"
                maxlength="20"
                :value="$data.agent.mobile"
              />
              <label for="adMobileNumber" class="label-require">Mobile Number</label>
              <ErrorFormField
                :msgArray="errors.adMobileNumber"
                v-if="errors.adMobileNumber !== undefined"
              />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adFax"
                placeholder="Fax"
                maxlength="20"
                :value="$data.agent.fax"
              />
              <label for="adMobileNumber" class="label-require">Fax</label>
              <ErrorFormField :msgArray="errors.adFax" v-if="errors.adFax !== undefined" />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adPinCode"
                placeholder="Pin Code"
                maxlength="20"
                :value="$data.agent.pin_code"
              />
              <label for="adPinCode" class="label-require">Pin Code</label>
              <ErrorFormField :msgArray="errors.adPinCode" v-if="errors.adPinCode !== undefined" />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <select class="form-select" id="adBusinessType" aria-label="adBusinessType">
                <option :selected="$data.agent.business_type == 0"></option>
                <option value="1" :selected="$data.agent.business_type == 1">
                  Sole Proprietor
                </option>
                <option value="2" :selected="$data.agent.business_type == 2">Partnership</option>
                <option value="3" :selected="$data.agent.business_type == 3">Joint Venture</option>
                <option value="4" :selected="$data.agent.business_type == 4">PVT LTD. CO.</option>
                <option value="5" :selected="$data.agent.business_type == 5">HUF</option>
                <option value="6" :selected="$data.agent.business_type == 6">Limited</option>
              </select>
              <label for="adBusinessType" class="label-require">Business Type</label>
              <ErrorFormField
                :msgArray="errors.adBusinessType"
                v-if="errors.adBusinessType !== undefined"
              />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adAgencyAddress"
                placeholder="Agency Address"
                maxlength="20"
                :value="$data.agent.agency_address"
              />
              <label for="adAgencyAddress" class="label-require">Agency Address</label>
              <ErrorFormField
                :msgArray="errors.adAgencyAddress"
                v-if="errors.adAgencyAddress !== undefined"
              />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adAddress2"
                placeholder="Address 2"
                :value="$data.agent.address2"
              />
              <label for="adAddress2">Address 2</label>
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <select
                class="form-select"
                id="adCountry"
                aria-label="adCountry"
                @change="adCountrySelectChange"
              >
                <option selected></option>
                <option
                  v-for="(item, index) in $data.countryList"
                  :value="item.no"
                  :key="index"
                  :selected="item.no == $data.agent.country_id"
                >
                  {{ item.name }}
                </option>
              </select>
              <label for="adCountry" class="label-require">Country</label>
              <ErrorFormField :msgArray="errors.adCountry" v-if="errors.adCountry !== undefined" />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <select
                class="form-select d-none"
                id="adStateSelect"
                aria-label="adState"
                @change="adStateSelectChange"
              >
                <option selected></option>
                <option
                  v-for="(item, index) in $data.stateList"
                  :value="item.no"
                  :key="index"
                  :selected="item.no == $data.agent.state_id"
                >
                  {{ item.name }}
                </option>
              </select>
              <input
                type="text"
                class="form-control"
                id="adStateInput"
                placeholder="State"
                :value="$data.agent.state_other"
              />
              <label class="label-require">State</label>
              <ErrorFormField :msgArray="errors.adState" v-if="errors.adState !== undefined" />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <select class="form-select d-none" id="adCitySelect" aria-label="adCitySelect">
                <option selected></option>
                <option
                  v-for="(item, index) in $data.cityList"
                  :value="item.no"
                  :key="index"
                  :selected="item.no == $data.agent.city_id"
                >
                  {{ item.name }}
                </option>
              </select>
              <input
                type="text"
                class="form-control"
                id="adCityInput"
                placeholder="City"
                :value="$data.agent.city_other"
              />
              <label for="adCityInput" class="label-require">City</label>
              <ErrorFormField :msgArray="errors.adCity" v-if="errors.adCity !== undefined" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="form-floating col-lg-3 col-md-6">
              <select
                class="form-select"
                id="adSecuritizationMode"
                aria-label="adSecuritizationMode"
              >
                <option value="3" :selected="$data.agent.securitization_mode == 3">Unsecure</option>
                <option value="1" :selected="$data.agent.securitization_mode == 1">
                  Bank Guarantee
                </option>
                <option value="2" :selected="$data.agent.securitization_mode == 2">
                  Post Dated Cheque
                </option>
              </select>
              <label for="adSecuritizationMode" class="label-require">Securitization Mode</label>
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <select class="form-select" id="adYearsInBusiness" aria-label="adYearsInBusiness">
                <option selected></option>
                <option
                  :value="i"
                  v-for="i in 12"
                  :key="'adYearsInBusiness_' + i.toString()"
                  :selected="i == $data.agent.years_in_business"
                >
                  {{ i }}
                </option>
              </select>
              <label for="adYearsInBusiness" class="label-require">Years in Business</label>
              <ErrorFormField
                :msgArray="errors.adYearsInBusiness"
                v-if="errors.adYearsInBusiness !== undefined"
              />
            </div>
            <div class="form-floating col-lg-3 col-md-6">
              <input
                type="text"
                class="form-control"
                id="adMonthlyBookingVolume"
                placeholder="Monthly Booking volume?"
                maxlength="20"
                :value="$data.agent.monthly_booking_volume"
              />
              <label for="adMonthlyBookingVolume" class="label-require"
                >Monthly Booking volume?</label
              >
              <ErrorFormField
                :msgArray="errors.adMonthlyBookingVolume"
                v-if="errors.adMonthlyBookingVolume !== undefined"
              />
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="" class="label-require">Office Space</label> <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="adOfficeSpace"
                  id="adOfficeSpaceOwner"
                  value="1"
                  :checked="1 == $data.agent.office_space"
                />
                <label class="form-check-label" for="adOfficeSpaceOwner">Owned</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="adOfficeSpace"
                  id="adOfficeSpaceRented"
                  :checked="0 == $data.agent.office_space"
                  value="0"
                />
                <label class="form-check-label" for="adOfficeSpaceRented"> Rented</label>
              </div>
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="mb-3 col-lg-3 col-md-6">
              <label for="" class="label-require">IATA Registered</label> <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="adIATARegistered"
                  id="adIATARegisteredYes"
                  value="1"
                  @change="adIATARegisteredChange"
                />
                <label class="form-check-label" for="adIATARegisteredYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="adIATARegistered"
                  id="adIATARegisteredNo"
                  value="0"
                  checked="checked"
                  @change="adIATARegisteredChange"
                />
                <label class="form-check-label" for="adIATARegisteredNo"> No</label>
              </div>
            </div>
            <div class="form-floating col-lg-3 col-md-6 d-none" id="adIATARegistered1">
              <input
                type="text"
                class="form-control"
                id="adIATACode"
                placeholder="IATA Code"
                maxlength="20"
                :value="$data.agent.iata_code"
              />
              <label for="adIATACode" class="label-require">IATA Code</label>
            </div>
            <div class="form-floating col-lg-3 col-md-6 d-none" id="adIATARegistered2">
              <input
                type="text"
                class="form-control"
                id="adIATATDSExcemption"
                placeholder="IATA Code"
                maxlength="20"
                :value="$data.agent.iata_tds_exemption"
              />
              <label for="adIATATDSExcemption" class="label-require">TDS Excemption</label>
            </div>
            <div class="form-floating col-lg-3 col-md-6 d-none" id="adIATARegistered3">
              <input
                type="text"
                class="form-control"
                id="adIATATDSPercentForExcemption"
                placeholder="TDS percent for Excemption"
                maxlength="20"
                :value="$data.agent.iata_tds_percent"
              />
              <label for="adIATATDSPercentForExcemption" class="label-require">
                TDS percent for Excemption
              </label>
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <textarea
                  class="form-control adTextarea"
                  placeholder="References: (optional)"
                  id="adReferences"
                  :value="$data.agent.references"
                ></textarea>
                <label for="adReferences">References: (optional)</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <textarea
                  class="form-control adTextarea"
                  placeholder="Consolidators: (optional)"
                  id="adConsolidators"
                  :value="$data.agent.consolidators"
                ></textarea>
                <label for="adConsolidators">Consolidators: (optional)</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <textarea
                  class="form-control adTextarea"
                  placeholder="Remarks: (optional)"
                  id="adRemarks"
                  :value="$data.agent.remarks"
                ></textarea>
                <label for="adRemarks">Remarks: (optional)</label>
              </div>
            </div>
          </div>
          <input type="hidden" class="form-control" id="pdId" :value="$data.agent.id" />
          <div class="text-center mt-3">
            <button class="btn btn-primary" @click="nextPage">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField.vue';
import Cookies from 'js-cookie';
// import LoadingIcons from '../../common/LoadingIcons.vue';
export default {
  name: 'PartnerSignup',
  components: {
    ErrorFormField,
    // LoadingIcons,
  },
  data: function () {
    return {
      errors: {},
      ajaxLoad: {
        next: false,
      },
      countryList: [],
      agent: {},
      stateList: [],
      cityList: [],
    };
  },
  methods: {
    async adCountrySelectChange() {
      var adCountry = document.getElementById('adCountry').value; //India : 102
      console.log('adCountry', adCountry);
      this.$emit('fromChildAgencySelectCountry', adCountry);
      if (adCountry == 102) {
        document.getElementById('adStateInput').classList.add('d-none');
        document.getElementById('adStateSelect').classList.remove('d-none');
        document.getElementById('adCityInput').classList.add('d-none');
        document.getElementById('adCitySelect').classList.remove('d-none');
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-state', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + adCountry,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.stateList = resData.stateList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
      document.getElementById('adStateInput').classList.remove('d-none');
      document.getElementById('adStateSelect').classList.add('d-none');
      document.getElementById('adCityInput').classList.remove('d-none');
      document.getElementById('adCitySelect').classList.add('d-none');
    },
    adIATARegisteredChange(event) {
      var adIATARegistered = event.target.value;
      if (adIATARegistered == '1') {
        document.getElementById('adIATARegistered1').classList.remove('d-none');
        document.getElementById('adIATARegistered2').classList.remove('d-none');
        document.getElementById('adIATARegistered3').classList.remove('d-none');
      } else {
        document.getElementById('adIATARegistered1').classList.add('d-none');
        document.getElementById('adIATARegistered2').classList.add('d-none');
        document.getElementById('adIATARegistered3').classList.add('d-none');
      }
    },
    async adStateSelectChange() {
      var adCountry = document.getElementById('adCountry').value; //India : 102

      if (adCountry == 102) {
        document.getElementById('adStateInput').classList.add('d-none');
        document.getElementById('adStateSelect').classList.remove('d-none');
        document.getElementById('adCityInput').classList.add('d-none');
        document.getElementById('adCitySelect').classList.remove('d-none');
        var adState = document.getElementById('adStateSelect').value;
        let self = this;
        // console.log("state $store ", this.$store.state)
        let apiUrl = process.env.VUE_APP_API_URL;

        await fetch(apiUrl + 'common/get-city', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: 'country=' + adCountry + '&state=' + adState,
        })
          .then((response) => response.json())
          .then((resData) => {
            // console.log('Success:', resData);
            // let messages = resData.messages
            if (resData.status == 'error') {
              alert('error while geting data ' + resData.message);
              return;
            }
            self.$data.cityList = resData.cityList;
          })
          .catch(function (err) {
            console.log('error', err);
          });
        return;
      }
    },
    async nextPage() {
      //eslint-disable-next-line
      var data = {};
      this.$data.errors = {};
      var pdId = document.getElementById('pdId').value;
      var adAgencyName = document.getElementById('adAgencyName').value;
      var adPAN = document.getElementById('adPAN').value;
      var adPANCardCopy = document.getElementById('adPANCardCopyHidden').value;
      var adMobileNumber = document.getElementById('adMobileNumber').value;
      var adFax = document.getElementById('adFax').value;
      var adPinCode = document.getElementById('adPinCode').value;
      var adBusinessType = document.getElementById('adBusinessType').value;
      var adBusinessTypeText =
        document.getElementById('adBusinessType').options[
          document.getElementById('adBusinessType').selectedIndex
        ].text;
      var adAgencyAddress = document.getElementById('adAgencyAddress').value;
      var adCountry = document.getElementById('adCountry').value; //India : 102
      var adCountryText =
        document.getElementById('adCountry').options[
          document.getElementById('adCountry').selectedIndex
        ].text;
      var adState = document.getElementById('adStateInput').value; //India : 02
      var adCity = document.getElementById('adCityInput').value; //India : 02
      var adYearsInBusiness = document.getElementById('adYearsInBusiness').value;
      var adMonthlyBookingVolume = document.getElementById('adMonthlyBookingVolume').value;
      if (adAgencyName == '') {
        this.$data.errors.adFirstName = ['This field is required'];
      }
      if (adPAN == '') {
        this.$data.errors.adPAN = ['This field is required'];
      } else {
        if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(adPAN)) {
          this.$data.errors.adPAN = ['Invalid pan. Please enter a correct one'];
        }
      }
      // if (adPANCardCopy == '') {
      //   this.$data.errors.adPANCardCopy = ['This field is required'];
      // }
      if (adMobileNumber == '') {
        this.$data.errors.adMobileNumber = ['This field is required'];
      }
      if (adFax == '') {
        this.$data.errors.adFax = ['This field is required'];
      }
      if (adPinCode == '') {
        this.$data.errors.adPinCode = ['This field is required'];
      } else {
        if (!/^\d+$/.test(adPinCode)) {
          this.$data.errors.adPinCode = ['Invalid pin code. Please enter a correct one'];
        }
      }
      if (adBusinessType == '') {
        this.$data.errors.adBusinessType = ['This field is required'];
      }
      if (adAgencyAddress == '') {
        this.$data.errors.adAgencyAddress = ['This field is required'];
      }
      if (adCountry == '') {
        this.$data.errors.adCountry = ['This field is required'];
      }
      if (adMonthlyBookingVolume == '') {
        this.$data.errors.adMonthlyBookingVolume = ['This field is required'];
      }
      var adStateId = 0,
        adStateText = adState,
        adCityId = 0,
        adCityText = adCity;
      if (adCountry == 102) {
        adState = '';
        adCity = '';
        //india
        adStateId = document.getElementById('adStateSelect').value; //India : 02
        adCityId = document.getElementById('adCitySelect').value; //India : 02
        adStateText =
          document.getElementById('adStateSelect').options[
            document.getElementById('adStateSelect').selectedIndex
          ].text;
        adCityText =
          document.getElementById('adCitySelect').options[
            document.getElementById('adCitySelect').selectedIndex
          ].text;
        if (adStateId == '') {
          this.$data.errors.adState = ['This field is required'];
        }
        if (adCityId == '') {
          this.$data.errors.adCity = ['This field is required'];
        }
      } else {
        if (adState == '') {
          this.$data.errors.adState = ['This field is required'];
        }
        if (adCity == '') {
          this.$data.errors.adCity = ['This field is required'];
        }
      }

      if (adYearsInBusiness == '') {
        this.$data.errors.adYearsInBusiness = ['This field is required'];
      }
      if (
        Object.keys(this.$data.errors).length !== 0 ||
        Object.getPrototypeOf(this.$data.errors) !== Object.prototype
      ) {
        // console.log('test data agency', data);
        // console.log('test (this.$data.errors agency', this.$data.errors);
        return;
      }
      data.id = pdId;
      data.adAgencyName = adAgencyName;
      data.adPAN = adPAN;
      data.adPANCardCopy = adPANCardCopy;
      data.adMobileNumber = adMobileNumber;
      data.adFax = adFax;
      data.adPinCode = adPinCode;
      data.adBusinessType = adBusinessType;
      data.adBusinessTypeText = adBusinessTypeText;
      data.adAgencyAddress = adAgencyAddress;
      data.adAddress2 = document.getElementById('adAddress2').value;

      data.adCountry = adCountry;
      data.adCountryText = adCountryText;
      data.adState = adState;
      data.adCity = adCity;
      data.adStateId = adStateId;
      data.adCityId = adCityId;
      data.adStateText = adStateText;
      data.adCityText = adCityText;
      data.adSecuritizationMode = document.getElementById('adSecuritizationMode').value;
      data.adSecuritizationModeText =
        document.getElementById('adSecuritizationMode').options[
          document.getElementById('adSecuritizationMode').selectedIndex
        ].text;
      data.adYearsInBusiness = adYearsInBusiness;
      data.adMonthlyBookingVolume = adMonthlyBookingVolume;
      data.adOfficeSpace = document.querySelector('input[name="adOfficeSpace"]:checked').value;
      data.adIATARegistered = document.querySelector(
        'input[name="adIATARegistered"]:checked'
      ).value;
      if (data.adIATARegistered == '1') {
        data.adIATACode = document.getElementById('adIATACode').value;
        data.adIATATDSExcemption = document.getElementById('adIATATDSExcemption').value;
        data.adIATATDSPercentForExcemption = document.getElementById(
          'adIATATDSPercentForExcemption'
        ).value;
      }
      data.adReferences = document.getElementById('adReferences').value;
      data.adConsolidators = document.getElementById('adConsolidators').value;
      data.adRemarks = document.getElementById('adRemarks').value;
      console.log('test data 2', data);
      // this.$emit('fromChildAgencyNextPage', data);
      this.$data.ajaxLoad.normal = true;
      // console.log("state $store ", this.$store.state)
      let self = this;
      let apiUrl = process.env.VUE_APP_API_URL;
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      let token = this.$store.state.token;

      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      await fetch(apiUrl + 'partner/update-agency', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);

          if (resData.status == 'error') {
            let messages = resData.messages;
            if (messages.adMobile != undefined) {
              self.$data.errors.adMobile = [
                'This  mobile number is existed. Please choose another one',
              ];
              console.log('admobile 1111', messages.adMobile);
              self.$data.errors.adMobile = messages.adMobile;
            }
            if (messages.adEmail != undefined) {
              self.$data.errors.adEmail = ['This email is existed. Please choose another one'];
              console.log('aEmail 2222', messages.adMobile);
              self.$data.errors.adEmail = messages.adEmail;
            }

            self.$data.agent.agency_name = data.adAgencyName;
            self.$data.agent.pan = data.adPAN;
            self.$data.agent.pan_card_copy = data.adPANCardCopy;
            self.$data.agent.mobile = data.adMobileNumber;
            self.$data.agent.fax = data.adFax;
            self.$data.agent.pin_code = data.adPinCode;
            self.$data.agent.business_type = data.adBusinessType;
            self.$data.agent.agency_address = data.adAgencyAddress;
            self.$data.agent.address2 = data.adAddress2;
            self.$data.agent.country_id = data.adCountry;
            self.$data.agent.state_id = data.adStateId;
            self.$data.agent.state_other = data.adState;
            self.$data.agent.city_id = data.adCityId;
            self.$data.agent.city_other = data.adCity;
            self.$data.agent.securitization_mode = data.adSecuritizationMode;
            self.$data.agent.years_in_business = data.adYearsInBusiness;
            self.$data.agent.monthly_booking_volume = data.adMonthlyBookingVolume;
            self.$data.agent.office_space = data.adOfficeSpace;
            self.$data.agent.iata_registered = data.adIATARegistered;
            self.$data.agent.iata_code = data.adIATACode;
            self.$data.agent.iata_tds_exemption = data.adIATATDSExcemption;
            self.$data.agent.iata_tds_percent = data.adIATATDSPercentForExcemption;
            self.$data.agent.references = data.adReferences;
            self.$data.agent.consolidators = data.adConsolidators;
            self.$data.agent.remarks = data.remarks;
            self.$data.ajaxLoad.normal = false;
            return;
          }
          Cookies.set('actionType', 'updated');
          self.$router.push('/partner/detail');
          self.$data.ajaxLoad.normal = false;
        })
        .catch(function (err) {
          console.log('error', err);
        });
      self.$data.ajaxLoad.normal = false;
    },
    previousPage() {
      this.$emit('fromChildAgencyPreviousPage');
    },
  },
  async created() {
    document.title = 'Trepr - Partner signup';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr caters to both travelers and seekers who want travel companions/send packing service for seekers and to travelers who want to earn money during their travel'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, trepr partner signup, trepr partner register, Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let token = this.$store.state.token;
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    let headerAuthentication = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    var ajaxCountryList = fetch(apiUrl + 'common/custom-country-list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
    }).then((response) => response.json());
    var ajaxDetail = fetch(apiUrl + 'partner/detail', {
      method: 'POST',
      headers: headerAuthentication,
      body: 'type=agent',
    }).then((response) => response.json());
    await Promise.all([ajaxCountryList, ajaxDetail])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        self.$data.countryList = resData[0].countryList;
        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.agent = resData[1].agent;
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
  mounted() {
    if (document.getElementById('adPANCardCopy') != null) {
      document.getElementById('adPANCardCopy').onchange = function () {
        if (this.files && this.files[0]) {
          var files = this.files[0];
          let limitedUploadSize = parseInt(process.env.VUE_APP_LIMITED_UPLOAD_SIZE);
          let mbSize = limitedUploadSize / 1000000;
          if (files.size > limitedUploadSize) {
            this.$data.errors.common = [
              'We only allow maximum ' +
                mbSize.toString() +
                'per file. Please choose a smaller file',
            ];
            return false;
          }
          var reader = new FileReader();
          reader.onload = function (e) {
            document.getElementById('adPANCardCopyHidden').value = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      };
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/new/partner/partner_signup.scss';
@import '../../../../node_modules/bs-stepper/dist/css/bs-stepper.min.css';
#bs-stepper-header .step {
  .btn-muted {
    background-color: #eee !important;
  }
  .step-trigger {
    padding: 10px;
  }
}
#bs-stepper-header .active .step-trigger {
  background-color: rgb(41, 115, 115) !important;
  span {
    color: #fff !important;
  }
}
</style>
