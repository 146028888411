<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <h1 class="text-primary">Update information</h1>
    <div class="row">
      <div class="col-sm-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              :class="{ 'nav-link': true, active: this.$route.params.slug == 'personal' }"
              id="personal-detail-tab"
              href="/partner/update/personal"
            >
              Personal detail
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              :class="{ 'nav-link': true, active: this.$route.params.slug == 'agent' }"
              id="agent-tab"
              href="/partner/update/agent"
            >
              Agent
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              :class="{ 'nav-link': true, active: this.$route.params.slug == 'agent-gst' }"
              id="agent-gst-tab"
              href="/partner/update/agent-gst"
            >
              Agent Gst
            </a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            :class="{ 'tab-pane fade show': true, active: this.$route.params.slug == 'personal' }"
            id="personal-detail-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            <UpdatePersonal v-if="this.$route.params.slug == 'personal'" />
          </div>
          <div
            :class="{ 'tab-pane fade show': true, active: this.$route.params.slug == 'agent' }"
            id="agent-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <UpdateAgency v-if="this.$route.params.slug == 'agent'" />
          </div>
          <div
            :class="{ 'tab-pane fade show': true, active: this.$route.params.slug == 'agent-gst' }"
            id="agent-gst-tab-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabindex="0"
          >
            <UpdateAgencyGst v-if="this.$route.params.slug == 'agent-gst'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdatePersonal from './UpdatePersonal.vue';
import UpdateAgency from './UpdateAgency.vue';
import UpdateAgencyGst from './UpdateAgencyGst.vue';
export default {
  name: 'Main',
  components: {
    UpdatePersonal,
    UpdateAgency,
    UpdateAgencyGst,
  },
  data: function () {
    return {
      personalData: {},
      agencyData: {},
      agencyCountry: '',
      gstData: {},
      ajax: {},
      errorFields: {},
      stepper: {},
    };
  },
};
</script>

<style scoped></style>
